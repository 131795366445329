import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { IAppState } from "@src/store/app/state"
import ConnectForm from "@components/ConnectForm"
import IconsContainer, { EIcons, IIconLinks } from "@components/IconsContainer"

import style from "./ConnectPage.scss"

export enum EFormStatus {
  INITIAL = 0,
  SENT = 1,
  ERROR = 2,
}

const ConnectPage: React.FunctionComponent = () => {
  const toDoHow: string = useSelector((state: IAppState) => state.toDoHow)
  const toDoWhat: string = useSelector((state: IAppState) => state.toDoWhat)

  const [formContent, setFormContent] = useState<JSX.Element>(<ConnectForm initialMessage={""}/>)

  useEffect(() => {
    setFormContent(<ConnectForm initialMessage={toDoHow + toDoWhat}/>)
  }, [toDoHow, toDoWhat])

  return (
    <div className={style.connectPage}>
      <div className={`${style.contentContainers} ${style.contentContainersLeft}`}/>
      <div className={`${style.contentContainers} ${style.contentContainersRight}`}>
        <h1>let us connect</h1>
        <p>
          I am based in Amsterdam, NL<br/>
          open for local or for remote opportunities<br/>
          anywhere in the world
        </p>
        <div className={style.contentContainersRightFormPlaceholder}>
          {formContent}
        </div>
        <div className={style.contentContainersRightIcons}>
          <div className={style.phoneIconsHolder}>
            <IconsContainer
                iconList={[
                  {
                    icon: EIcons.PHONE,
                    url: "tel:+31625370106"
                  },
                  {
                    icon: EIcons.WHATSAPP,
                    url: "https://wa.me/31625370106"
                  },
                ]}
              />
            <p>+31 625 370106</p>
          </div>
          <div className={style.socialIconsHolder}>
            <IconsContainer
              iconList={[
                {
                  icon: EIcons.MAIL,
                  url: "mailto:szabolcs@easymonday.org"
                },
                {
                  icon: EIcons.MEDIUM,
                  url: "https://medium.com/easy-monday"
                },
                {
                  icon: EIcons.TWITTER,
                  url: "https://twitter.com/S4n3L"
                },
                {
                  icon: EIcons.LINKEDIN,
                  url: "https://www.linkedin.com/in/mrszabolcsfarkas/"
                }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectPage
