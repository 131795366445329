import axios from "axios"
import React, { useEffect, useRef, useState } from "react"

import { isValidEmail } from "./emailHelper"

import style from "./ConnectForm.scss"

export enum EFormStatus {
  INITIAL = 0,
  SENT = 1,
  ERROR = 2,
}

enum InputTypes {
  TEXT = "text",
  EMAIL = "email",
}

enum InputID {
  NAME = "name",
  EMAIL = "email",
  TEXT = "text",
}

interface IConnectFormProps {
  initialMessage: string
}

const ConnectForm: React.FunctionComponent<IConnectFormProps> = (props) => {
  const { initialMessage } = props

  const [enteredName, setEnteredName] = useState<string>("")
  const [enteredMail, setEnteredMail] = useState<string>("")
  const [enteredTextMsg, setEnteredTextMsg] = useState<string>(initialMessage)

  const [formStatus, setFormStatus] = useState<EFormStatus>(EFormStatus.INITIAL)
  const [formContent, setFormContent] = useState<JSX.Element>(null)

  //  Ref hooks for focus
  const nameInput = useRef(null)
  const emailInput = useRef(null)

  React.useEffect(() => {
    switch (formStatus) {
      case EFormStatus.INITIAL: {
        setFormContent(
          <form className={style.connectFormInitial} onSubmit={onSubmit} autoComplete={"off"} noValidate={true}>
            <input type={"hidden"} value={"prayer"} />
            <div className={style.connectFormInitialGroup}>
              <label
                htmlFor={InputID.NAME}
                className={style.connectFormInitialLabel}
              >
                your name
              </label>
              <input
                ref={nameInput}
                id={InputID.NAME}
                className={style.connectFormInitialInput}
                type={InputTypes.TEXT}
                required={true}
                placeholder={""}
                autoComplete="off"
                value={enteredName}
                onChange={onInputChange}
              />
            </div>
            <div className={style.connectFormInitialGroup}>
              <label htmlFor={InputID.EMAIL} className={style.connectFormInitialLabel}>your email</label>
              <input
                ref={emailInput}
                id={InputID.EMAIL}
                className={style.connectFormInitialInput}
                type={InputTypes.TEXT}
                required={true}
                placeholder={""}
                autoComplete="off"
                value={enteredMail}
                onChange={onInputChange}
              />
            </div>
            <div className={style.connectFormInitialGroup}>
              <label
                style={{ height: "22rem" }}
                htmlFor={InputID.TEXT}
                className={style.connectFormInitialLabel}
              >
                your message
              </label>
              <textarea
                id={InputID.TEXT}
                className={`${style.connectFormInitialInput} ${style.connectFormInitialTextArea}`}
                placeholder=""
                autoComplete="off"
                value={enteredTextMsg}
                onChange={onTextAreaChange}
                rows={9}
              />
              <button className={style.connectFormInitialButton}>Submit</button>
            </div>
          </form>
        )
        break
      }
      case EFormStatus.SENT: {
        setFormContent(
          <div className={style.connectFormAction}>
            <h1 className={style.connectFormActionText}>
              thank you
              <br/>
              <span className={style.connectFormActionTextThin}>your message</span><br/>
              <span className={style.connectFormActionTextThin}>is on the way</span><br/>
              <span className={style.connectFormActionTextThin}>to my mailbox</span><br/>
              <br/>
              <span className={style.connectFormActionTextThin}>I will get back to you soon</span><br/>
              <span className={style.connectFormActionTextThin}></span>
            </h1>
          </div>
          )
        break
      }
      case EFormStatus.ERROR: {
        setFormContent(
          <div className={style.connectFormAction}>
          <h1 className={style.connectFormActionText}>
            ooooops<br/>
            <span className={style.connectFormActionTextThin}>something</span><br/>
            <span className={style.connectFormActionTextThin}>went wrong</span><br/>
            <span className={style.connectFormActionTextThin}>
              but no worries<br/>
              just hit<br/>
              the mail icon bellow<br/>
              to send a message
            </span><br/>
          </h1>
          </div>
        )
        break
      }
    }
  }, [formStatus, enteredName, enteredMail, enteredTextMsg])  // eslint-disable-line

  const onSubmit = (e: any): any => {
    e.preventDefault()
    if (enteredName === "") {
      nameInput.current.focus()
      return null
    }
    if (enteredMail === "" || !isValidEmail(enteredMail)) {
      emailInput.current.focus()
      return null
    }
    axios({
      method: "post",
      url: "https://formcarry.com/s/m7YLBUKRBpg",
      data: {
        name: enteredName,
        mail: enteredMail,
        message: enteredTextMsg,
      },
    })
    .then((res) => {
      setFormStatus(EFormStatus.SENT)
      setTimeout(() => {
        setEnteredName("")
        setEnteredMail("")
        setEnteredTextMsg("")
        setFormStatus(EFormStatus.INITIAL)
      }, 5000)
    })
    .catch((err) => {
      setFormStatus(EFormStatus.ERROR)
    })
  }

  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    switch (e.currentTarget.id) {
      case InputID.NAME: {
        setEnteredName(e.currentTarget.value)
        break
      }
      case InputID.EMAIL: {
        setEnteredMail(e.currentTarget.value)
        break
      }
    }
  }

  const onTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setEnteredTextMsg(e.currentTarget.value)
  }

  return formContent
}

export default ConnectForm
