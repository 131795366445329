import * as React from "react"
import SVG from "react-inlinesvg"

import iconAWS from "@static/svg/aws.svg"
import iconMongoDB from "@static/svg/mongodb.svg"
import iconNode from "@static/svg/node.svg"
import iconRails from "@static/svg/rails.svg"
import iconReact from "@static/svg/react.svg"
import iconLinkedIn from "@static/svg/LinkedIN.svg"
import iconMail from "@static/svg/Mail.svg"
import iconMedium from "@static/svg/Medium.svg"
import iconPhone from "@static/svg/Phone.svg"
import iconTwitter from "@static/svg/Twitter.svg"
import iconWhatsApp from "@static/svg/WhatsApp.svg"

import style from "./IconsContainer.scss"

export enum EIcons {
  AWS = iconAWS,
  MONGO = iconMongoDB,
  NODE = iconNode,
  RAILS = iconRails,
  REACT = iconReact,
  LINKEDIN = iconLinkedIn,
  MAIL = iconMail,
  PHONE = iconPhone,
  TWITTER = iconTwitter,
  MEDIUM = iconMedium,
  WHATSAPP = iconWhatsApp
}

export interface IIconLinks {
  icon: EIcons,
  url: string
}

interface IIconsContainerProps {
  iconList: IIconLinks[]
}

const IconsContainer: React.FunctionComponent<IIconsContainerProps> = (props) => {
  const { iconList } = props

  const svgArray = iconList.map((mappedIcons, index) => {
    return (
      <div key={index}>
        <a className={style.iconContainer} href={mappedIcons.url} rel="noopener noreferrer" target="_blank">
          <SVG className={style.icon} src={mappedIcons.icon.toString()}/>
        </a>
      </div>
    )
  })

  return (
    <div className={style.iconsContainer}>
      {svgArray}
    </div>
  )
}

export default IconsContainer
